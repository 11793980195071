<template>
  <div class="info">
    <section class="banner">
      <img src="../../images/home-banner.webp" />
    </section>
    <section class="content">
      <div class="header">
        <h2 class="title">行业资讯</h2>
      </div>
      <ul class="list" v-loading="loading">
        <li
          class="item"
          v-for="(item, index) in infoList"
          :key="item.id"
          @click="onClickItem(index)"
        >
          <div class="icon">
            <img :src="item.images" />
          </div>
          <div class="right">
            <h3 class="title ellipsis">{{ item.name }}</h3>
            <p class="desc">{{ item.abstract }}</p>
          </div>
          <div class="time">{{ item.time | timeFilter }}</div>
        </li>
      </ul>
    </section>
    <div class="showMore">
      <div class="btn" @click="showMore">查看更多</div>
    </div>
  </div>
</template>

<script>
import { infoList } from '@/api/common'

import { timeFormat } from '@/utils/index'

export default {
  data() {
    return {
      infoList: [],
      page: 1,
      hasData: true,
      loading: true,
    }
  },
  mounted() {
    this.getInfoList({ type: 'refresh' })
  },
  methods: {
    onClickItem(index) {
      this.$router.push({
        name: 'infoDetail',
        params: { id: this.infoList[index].id },
      })
    },
    async getInfoList({ type = 'refresh' }) {
      try {
        let res = await infoList({
          page: this.page,
        })
        setTimeout(() => {
          this.loading = false
        }, 400)
        let list = res.data
        if (list && list.length > 0) {
          if (type == 'refresh') {
            this.infoList = [...list]
          } else if (type == 'more') {
            this.infoList = [...this.infoList, ...list]
          }
        } else {
          this.hasData = false
          this.$message('没有更多数据了')
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    showMore() {
      if (this.hasData) {
        this.loading = true
        this.page++
        this.getInfoList({ type: 'more' })
      } else {
        this.$message('没有更多数据了')
      }
    },
  },
  filters: {
    timeFilter(time) {
      return timeFormat(time * 1000, 'YYYY-M-D')
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/var.scss';

.info {
  .banner {
    width: 100%;
    overflow: hidden;
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
  }
  .content {
    width: 100%;
    background-color: #f4f5f9;
    .header {
      padding: 50px 0;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 15px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 3px;
          background-color: #373737;
          left: 50%;
          bottom: 10px;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
    }
    .list {
      width: 1200px;
      margin: 0 auto;
      text-align: left;
      .item {
        box-sizing: border-box;
        width: 572px;
        height: 165px;
        padding: 20px 18px 13px;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px 0px rgba(194, 194, 194, 0.17);
        border-radius: 11px;
        display: inline-flex;
        position: relative;
        margin: 0 13px 35px;
        cursor: pointer;
        .icon {
          width: 124px;
          height: 132px;
          object-fit: cover;
        }
        .right {
          flex: 1;
          margin-left: 16px;
          width: 397px;
          .title {
            font-size: 21px;
            line-height: 25px;
            font-weight: bold;
            color: #373737;
            margin-top: 6px;
          }
          .desc {
            font-size: 11px;
            color: #373737;
            line-height: 21px;
            margin-top: 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
        .time {
          position: absolute;
          right: 18px;
          bottom: 19px;
          font-size: 11px;
          color: #373737;
          line-height: 1;
        }
      }
    }
  }
  .showMore {
    display: flex;
    justify-content: center;
    padding-bottom: 48px;
    background-color: #f4f5f9;
    .btn {
      background-color: $saffron;
      font-size: 18px;
      font-weight: bold;
      color: #444446;
      padding: 14px 24px;
      cursor: pointer;
    }
  }
}
</style>
